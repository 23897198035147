import React from "react"
import { AnimatePresence } from 'framer-motion'

import { UIStateProvider } from "./src/contexts/UIStateContext"
import { NavStateProvider } from "./src/contexts/NavStateContext"

export const wrapRootElement = ({ element }) => (
  <UIStateProvider>
    <NavStateProvider>
      {element}
    </NavStateProvider>
  </UIStateProvider>
)

export const wrapPageElement = ({element}) => (
    <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
  );

export const onRouteUpdate = ({ location }) => {
  let { hash } = location
  if (hash) {

    const Anchor = document.querySelectorAll(`${hash}`)[0];
    const Header = document.querySelectorAll('header')[0]
    let Position = null;

    if (Anchor) {
      Position = Anchor.offsetTop - Header.offsetHeight;
    }

    window.scrollTo({ top: Position, behavior: "smooth" });
  }
};