import React, { useState, createContext, useContext } from "react"

const defaultState = {
    navHeight: 184,
    headerHeight: 84,
    totalOffset: 195,
    mobileoffset: 110,
  }

export const UIStateContext = createContext(defaultState)

export const UIStateProvider = ({ children }) => {
    const [navHeight, setNavHeight] = useState(defaultState.navHeight)
    const [headerHeight, setHeaderHeight] = useState(defaultState.headerHeight)
    const [totalOffset, setTotalOffset] = useState(defaultState.totalOffset)
    const [mobileoffset, setmobileoffset] = useState(defaultState.mobileoffset)

    const providerState = {
        navHeight,
        headerHeight,
        totalOffset,
        mobileoffset,
        setNavHeight,
        setHeaderHeight,
        setTotalOffset,
        setmobileoffset
    }

    return(
        <UIStateContext.Provider value={providerState}>
            {children}
        </UIStateContext.Provider>
    )
}

export const useUIState = () => {
    const context = useContext(UIStateContext)
    if (context === undefined) {
        console.log('useUIState must be used within a UIStateProvider')
        return
    }
    return context
}