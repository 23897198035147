exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-architektur-alle-js": () => import("./../../../src/pages/architektur/alle.js" /* webpackChunkName: "component---src-pages-architektur-alle-js" */),
  "component---src-pages-architektur-architektur-category-architektur-slug-js": () => import("./../../../src/pages/architektur/{Architektur.category}/{Architektur.slug}.js" /* webpackChunkName: "component---src-pages-architektur-architektur-category-architektur-slug-js" */),
  "component---src-pages-architektur-architektur-category-js": () => import("./../../../src/pages/architektur/{Architektur.category}.js" /* webpackChunkName: "component---src-pages-architektur-architektur-category-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-design-alle-js": () => import("./../../../src/pages/design/alle.js" /* webpackChunkName: "component---src-pages-design-alle-js" */),
  "component---src-pages-design-design-category-design-slug-js": () => import("./../../../src/pages/design/{Design.category}/{Design.slug}.js" /* webpackChunkName: "component---src-pages-design-design-category-design-slug-js" */),
  "component---src-pages-design-design-category-js": () => import("./../../../src/pages/design/{Design.category}.js" /* webpackChunkName: "component---src-pages-design-design-category-js" */),
  "component---src-pages-forschung-forschung-slug-js": () => import("./../../../src/pages/forschung/{Forschung.slug}.js" /* webpackChunkName: "component---src-pages-forschung-forschung-slug-js" */),
  "component---src-pages-forschung-index-js": () => import("./../../../src/pages/forschung/index.js" /* webpackChunkName: "component---src-pages-forschung-index-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-resonator-js": () => import("./../../../src/pages/resonator.js" /* webpackChunkName: "component---src-pages-resonator-js" */)
}

