import { navigate } from "gatsby"
import React, { useState, createContext, useContext } from "react"

const defaultState = {
    navbarOpen: false,
    architectureOpen: false,
    designOpen: false,
    projectsOpen: false,
    resonatorOpen: false,
    kontaktOpen: false,
    dsgvoOpen: false,
    inViewport: null
}

export const NavStateContext = createContext(defaultState)

export const NavStateProvider = ({ children }) => {
    const [navbarOpen, setNavbarOpen] = useState(defaultState.navbarOpen)
    const [architectureOpen, setArchitectureOpen] = useState(defaultState.architectureOpen)
    const [designOpen, setDesignOpen] = useState(defaultState.designOpen)
    const [projectsOpen, setProjectsOpen] = useState(defaultState.projectsOpen)
    const [resonatorOpen, setResonatorOpen] = useState(defaultState.resonatorOpen)
    const [kontaktOpen, setKontaktOpen] = useState(defaultState.kontaktOpen)
    const [dsgvoOpen, setDsgvoOpen] = useState(defaultState.dsgvoOpen)
    const [inViewport, setInViewport] = useState(defaultState.inViewport)

    const handleDropdownClick = (e, type, breakpoints) => {
        e.preventDefault()

        if (breakpoints.xs || breakpoints.sm || breakpoints.md) {
            switch (type) {
                case "architecture":
                    setArchitectureOpen(true)
                    setDesignOpen(false)
                    setProjectsOpen(true)
                    setResonatorOpen(false)
                    setKontaktOpen(false)
                    setDsgvoOpen(false)
                    break
                case "design":
                    setArchitectureOpen(false)
                    setDesignOpen(true)
                    setProjectsOpen(true)
                    setResonatorOpen(false)
                    setKontaktOpen(false)
                    setDsgvoOpen(false)
                    break
                case "projects":
                    setProjectsOpen(true)
                    setResonatorOpen(false)
                    setKontaktOpen(false)
                    setDsgvoOpen(false)
                    break
                case "resonator":
                    navigate('/resonator#about')
                    setProjectsOpen(false)
                    setResonatorOpen(true)
                    setKontaktOpen(false)
                    setDsgvoOpen(false)
                    break
            }
        } else {
            switch (type) {
                case "architecture":
                    navigate('/architektur/alle/')
                    setArchitectureOpen(true)
                    setDesignOpen(false)
                    setProjectsOpen(true)
                    setResonatorOpen(false)
                    setKontaktOpen(false)
                    setDsgvoOpen(false)
                    break
                case "design":
                    navigate('/design/alle/')
                    setArchitectureOpen(false)
                    setDesignOpen(true)
                    setProjectsOpen(true)
                    setResonatorOpen(false)
                    setKontaktOpen(false)
                    setDsgvoOpen(false)
                    break
                case "projects":
                    navigate('/architektur/alle/')
                    setArchitectureOpen(true)
                    setDesignOpen(false)
                    setProjectsOpen(true)
                    setResonatorOpen(false)
                    setKontaktOpen(false)
                    setDsgvoOpen(false)
                    break
                case "resonator":
                    navigate('/resonator')
                    setArchitectureOpen(false)
                    setDesignOpen(false)
                    setProjectsOpen(false)
                    setResonatorOpen(true)
                    setKontaktOpen(false)
                    setDsgvoOpen(false)
                    break
            }
        }

    }

    const handleItemClick = (e, type) => {
        setNavbarOpen(false)

        switch (type) {
            case "close-all":
                setArchitectureOpen(false)
                setDesignOpen(false)
                setProjectsOpen(false)
                setResonatorOpen(false)
                setNavbarOpen(false)
                setKontaktOpen(false)
                setDsgvoOpen(false)
                break
            case "research":
                setArchitectureOpen(false)
                setDesignOpen(false)
                setProjectsOpen(true)
                setResonatorOpen(false)
                setNavbarOpen(false)
                setKontaktOpen(false)
                setDsgvoOpen(false)
                break
        }
    }


    const providerState = {
        designOpen: designOpen,
        setDesignOpen: setDesignOpen,
        projectsOpen: projectsOpen,
        setProjectsOpen: setProjectsOpen,
        resonatorOpen: resonatorOpen,
        setResonatorOpen: setResonatorOpen,
        navbarOpen: navbarOpen, 
        setNavbarOpen: setNavbarOpen,
        architectureOpen: architectureOpen,
        kontaktOpen: kontaktOpen,
        setKontaktOpen: setKontaktOpen,
        dsgvoOpen: dsgvoOpen,
        setDsgvoOpen: setDsgvoOpen,
        setArchitectureOpen: setArchitectureOpen,
        handleDropdownClick: handleDropdownClick,
        handleItemClick: handleItemClick,
        inViewport: inViewport,
        setInViewport: setInViewport
    }

    return(
        <NavStateContext.Provider value={providerState}>
            {children}
        </NavStateContext.Provider>
    )
}

export const useNavState = () => {
    const context = useContext(NavStateContext)
    if (context === undefined) {
        throw new Error('useNavState must be used within a NavStateProvider')
    }
    return context
}